(function () {
  $(".property-slider-for").slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    asNavFor: ".property-slider-nav",
  });
  $(".property-slider-nav").slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    vertical: true,
    asNavFor: ".property-slider-for",
    dots: false,
    arrows : false,
    focusOnSelect: true,
    verticalSwiping: true,
    centerMode:false,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          vertical: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          vertical: false,
        },
      },
      {
        breakpoint: 580,
        settings: {
          vertical: false,
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 380,
        settings: {
          vertical: false,
          slidesToShow: 2,
        },
      },
    ],
  });
})();
