(function() {
  $('.burger-menu').on('click', function(){
    $('.burger-menu, .main-nav, .main-header').toggleClass('active');
    $('body').toggleClass('look');
  })

  let header = $(".main-header"),
  scrollPrev = 0;

$(window).scroll(function() {
  let scrolled = $(window).scrollTop();

  if (scrolled > 300 && scrolled > scrollPrev) {
    header.addClass("off");
    header.addClass("out");
  } else {
    header.removeClass("off");
  }

  scrollPrev = scrolled;
  if (scrollPrev < 200) {
    header.removeClass("out");
  }
});

$('.links-sidebar__btn').on('click', function(){
  $(this).toggleClass('active');
  $('.links-sidebar').toggleClass('active');
})
})()