(function () {
  $(".like-select-js").on("click", function (event) {
    $(this).parent().find(".like-select__dropdown").slideToggle("fast");
    $(this).parent().find(".like-select__arrow").toggleClass("active");
  });
  $(document).on("click", function (event) {
    var $trigger = $(".like-select-js").parent();
    if ($trigger !== event.target && !$trigger.has(event.target).length) {
      $(".like-select__dropdown").slideUp("fast");
      $(".like-select__arrow").removeClass("active");
    }
  });
})();
