(function () {
 $(".main-select").each(function () {
   const $this = $(this);
   const placeholder = $(this).attr("data-placeholder");
   const dropdownParentEl = $(this).closest(".group-select");
   $this.select2({
    // closeOnSelect : false,
     minimumResultsForSearch: -1,
     dropdownParent: dropdownParentEl,
     placeholder: placeholder,
     containerCssClass: "select-main",
     dropdownCssClass: "select-main-dropdown",
   });
 }); 
})();
